import React from "react";
import "./App.css";
import { Home } from "./Pages/Home";
import { Footer } from "./Pages/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPage from "./Pages/Privacy";
import SeedWheat from "./Pages/SeedWheat";
import GridSoilSampling from "./Pages/GridSoilSampling";
import Navbar from "./Pages/Navbar";
import TestPlots from "./Pages/TestPlots";
import ArchivedPlots from "./Pages/ArchivedPlots";
import Alfalfa from "./Pages/Alfalfa";
import Channel from "./Pages/Channel";
import ClimateFieldView from "./Pages/ClimateFieldView";
import PivotBio from "./Pages/PivotBio";
import VariableRateSeeding from "./Pages/VariableRateSeeding";
class App extends React.Component {
  constructor(params) {
    super(params);
    this.state = { current: "" };
  }
  render() {
    return (
      <>
        <Router>
          <Navbar current={this.state.current} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Channel" element={<Channel />} />
            <Route path="/PrivacyPage" element={<PrivacyPage />} />
            <Route path="/WLAlfalfa" element={<Alfalfa />} />
            <Route path="/ClimateFieldView" element={<ClimateFieldView />} />
            <Route path="/PivotBio" element={<PivotBio />} />
            {/*<Route
              path="/VariableRateSeeding"
              element={<VariableRateSeeding />}
    />*/}
            <Route path="/SeedWheat" element={<SeedWheat />} />
            <Route path="/GridSoilSampling" element={<GridSoilSampling />} />
            <Route path="/TestPlots" element={<TestPlots />} />
            <Route path="/ArchivedPlots" element={<ArchivedPlots />} />
          </Routes>

          <Footer />
        </Router>
      </>
    );
  }
}

export default App;
