import React from "react";
export default function SeedWheat() {
  return (
    <>
      <div className="parallaxSeedWheat">
        <div className="SecondaryTextTitle">Seed Wheat</div>
      </div>
      <div className="gridContInfoPage">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBox">
          <div className="SecondaryTextLine">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 0,
                paddingRight: "0%",
                zIndex: 1,
              }}
            >
              <div style={{}}>
                <img
                  src={"/Pictures/Logo-removebg no name.png"}
                  className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
                  alt=""
                />
              </div>
            </div>
            <div className="SecondaryTextCont">
              <div className="SecondaryText">
                Offering certified KWA, OGI, West Bred and any certified wheat
                variety you need on your operation.
              </div>
              <div className="SecondaryText" style={{ textAlign: "center" }}>
                Call us!
              </div>
              <div className="SecondaryText">
                *For certified growers only, we offer registered seed wheat. 
              </div>
            </div>
            {/*<div className="InfoImgCont">
              <div className="InfoImgflex">
                <img
                  src={"/Pictures/SeedWheat.png"}
                  className="SecondaryImg"
                  alt=""
                ></img>
              </div>
          </div>*/}
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
