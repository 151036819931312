import React from "react";
export default function TestPlots() {
  return (
    <>
      <div className="parallaxTopDown">
        <div className="SecondaryTextTitle">Test Plots</div>
      </div>
      <div className="gridContInfoPage">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBox">
          <div className="SecondaryTextLine">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 0,
                paddingRight: "0%",
                zIndex: 1,
              }}
            >
              <div style={{}}>
                <img
                  src={"/Pictures/Logo-removebg no name.png"}
                  className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
                  alt=""
                />
              </div>
            </div>

            <div className="SecondaryTextCont">
              <div className="SecondaryText">
                The use of test plots at Everhart Precision provide reliable and
                accurate data collection specific to your agricultural
                conditions. We monitor factors like growth patters and nutrition
                stats to monitor water use efficiency and maximize crop yield.
                With a commitment to continuous improvement, we embrace the
                invaluable insights gained from test plots to offer you
                cutting-edge solutions that maximize yields, minimize inputs,
                and cultivate sustainable success.
              </div>
            </div>
          </div>
          <div className="ReportImgCont">
            <div className="ReportImgTitle">Bev’s DL Corn 23</div>
            <img
              src={"/Pictures/Bev’s 23_report.png"}
              className="ReportImg"
              alt=""
            />
            <div className="ReportImgTitle">Marquette Dryland</div>
            <img
              src={"/Pictures/K4 23_report.png"}
              className="ReportImg"
              alt=""
            />
            <div className="ReportImgTitle">Marquette limited irrigation</div>
            <img
              src={"/Pictures/Larson report.png"}
              className="ReportImg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
