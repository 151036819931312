import React from "react";

export default function Alfalfa() {
  return (
    <>
      <div className="parallaxAlf">
        <div className="SecondaryTextTitle">W-L Alfalfas</div>
      </div>
      <div className="GridCont">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBoxLink">
          <div style={{}}>
            <img
              src={"/Pictures/Logo-removebg no name.png"}
              className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
              alt=""
            />
          </div>

          <div className="SecondaryBody">
            <div className="SecondaryTextLineLink">
              <div style={{ flex: 1, paddingRight: "1%" }}>
                <div
                  className="SecondaryText"
                  style={{ paddingBottom: "2%", flex: 1 }}
                >
                  Grow your confidence season after season with our proven
                  alfalfa seed. W-L Alfalfas® has increased yields and improved
                  forage quality for more than half a century. Our lineup of
                  conventional, Roundup Ready® and HarvXtra® Alfalfa with
                  Roundup Ready technology can make a difference in your fields.
                </div>
              </div>
              <div className="SecondaryImgCont">
                <a
                  href="https://www.wlalfalfas.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="SecondaryLink"
                  style={{ width: "100%", marginLeft: "0%" }}
                >
                  <div className="LinkImgCont">
                    <img
                      src={"/Pictures/WLalfalfas.png"}
                      className="LinkImg"
                      alt=""
                    ></img>
                  </div>
                  <div>
                    <div className="LinkText">
                      <div className="special">Link</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
