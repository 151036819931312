import { useState } from "react";
import { send } from "emailjs-com";

function Email() {
  const [toSend, setToSend] = useState({
    from_name: "",
    from_num: "",
    message: "",
    reply_to: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      "service_l4risoc",
      "template_kymts6o",
      toSend,
      "user_9mbxQoPbqP8De6BMNtzeC"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="EmailComp">
      {
        <form onSubmit={onSubmit}>
          <div className="HomeEmptyThree">Contact Us</div>
          <input
            className="FromName"
            type="text"
            name="from_name"
            placeholder="From Name"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <input
            className="FromNum"
            type="text"
            name="from_num"
            placeholder="Phone Number"
            value={toSend.from_num}
            onChange={handleChange}
          />
          <textarea
            className="Message"
            type="text"
            name="message"
            placeholder="Your Message"
            value={toSend.message}
            onChange={handleChange}
          />
          <input
            className="Email"
            type="text"
            name="reply_to"
            placeholder="Your Email"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <button className="Submit" type="submit">
            Submit
          </button>

          <div className="HomeEmptyFour">Or at 785-643-0835</div>
        </form>
      }
    </div>
  );
}
export default Email;
