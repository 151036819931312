import React from "react";
import { Link } from "react-router-dom";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiMapPin2Fill } from "react-icons/ri";
export class Footer extends React.Component {
  render() {
    return (
      <div className="ContainerFooter">
        <ul className="Footer-foot">
          <li>
            <img
              src={"/Pictures/Logo-removebg.png"}
              className="FooterLogo"
              alt=""
            />
          </li>
          <Link to={"PrivacyPage"} className="privacyLink">
            <div> Privacy Policy</div>
          </Link>
          <div className="Copyright">Copyright © 2022 All rights reserved</div>
        </ul>
        <div className="Footer-Contact">
          <div className="Footer-Contact-Title">
            <BsFillPhoneFill /> Phone
          </div>
          <div className="Footer-Contact-Info-Phone">
            <div>Matt Everhart: (785)643-0835</div>
            <br></br>
            <div>Lane Hensley: (620)482-2715</div>
          </div>
          <div className="Footer-Contact-Empty"></div>
          <div className="Footer-Contact-Title">
            <MdEmail /> Email
          </div>
          <div className="Footer-Contact-Info">
            <div
              onClick={() => (window.location = "mailto:")}
              className="Btn-Email"
            >
              <div>everhartmatthew@ymail.com</div>
              {
                //tanner44schafer@gmail.com
              }
              {/*everhartmatthew@ymail.com*/}
            </div>
          </div>
          <div className="Footer-Contact-Empty"></div>
          <div className="Footer-Contact-Title">
            <RiMapPin2Fill /> Mail
          </div>
          <div className="Footer-Contact-Info">
            <div>Physical address: 9512 E Hedberg Rd Gypsum, KS 67448</div>
            <br></br>
            <div>Mailing address: 10253 S Donmyer Rd Gypsum, KS 67448</div>
          </div>
        </div>
      </div>
    );
  }
}
