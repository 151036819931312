import React, { useState } from "react";
export default function ArchivedPlots() {
  const [imageClicked, setImageClicked] = useState({
    first: false,
    second: false,
    ground: false,
  });
  const onClickHandler = (order) => {
    setImageClicked((prevState) => ({
      ...prevState,
      [order]: !prevState[order],
    }));
  };
  return (
    <>
      <div className="gridContInfoPage">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBox">
          <div className="SecondaryTextLine">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 0,
                paddingRight: "0%",
                zIndex: 1,
              }}
            >
              <div style={{}}>
                <img
                  src={"/Pictures/Logo-removebg no name.png"}
                  className="backImg"
                  style={{ top: 0 }}
                  alt=""
                />
              </div>

              <table style={{ width: "100%", backgroundColor: "white" }}>
                <tr>
                  <th>
                    <button
                      onClick={() => onClickHandler("ground")}
                      className="ground"
                    >
                      Corn
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => onClickHandler("first")}
                      className="first"
                    >
                      Soybeans
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => onClickHandler("second")}
                      className="second"
                    >
                      Grain Sorghum
                    </button>
                  </th>
                </tr>
                <tr>
                  <td>Alfreds Futterkiste</td>
                  <td>Maria Anders</td>
                  <td>Germany</td>
                </tr>
                <tr>
                  <td>Centro comercial Moctezuma</td>
                  <td>Francisco Chang</td>
                  <td>Mexico</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="ReportImgCont">
            <div className="ReportImgTitle">Bev’s DL Corn 23</div>
            <img
              src={"/Pictures/Bev’s 23_report.png"}
              className="ReportImg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
