import React from "react";

export default function GridSoilSampling() {
  return (
    <>
      <div className="parallaxGSS">
        <div className="SecondaryTextTitle">Grid Soil Sampling</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(40 55 75)",
        }}
      >
        <div className="GridContLong">
          <div
            className="SecondaryTextBoxGSS"
            style={{ flexDirection: "column" }}
          >
            <div className="SecondaryGrid">
              <div
                className="SecondaryBody"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomWidth: 1,
                  paddingBottom: "1%",
                  marginTop: "0px",
                }}
              >
                <div
                  className="SecondaryTextTitleGSS"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    paddingRight: 0,
                    display: "contents",
                  }}
                >
                  Know Your Soil
                </div>
              </div>
              <div
                className="SecondaryText"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  borderTopWidth: 0,
                  padding: 0,
                  paddingTop: "1%",
                }}
              >
                All the fields have underlying variability and you know that
                from ridding in the combine cab. Grid soil sampling allows you
                to quantify that soil fertility variability and help correct or
                utilize that variability. Grid soil sampling information will
                help us create a specific fertilizer plan for each acre. Often,
                we can save your money with your fertilizer plan, but our main
                goal is to produce a higher yield in each of your fields.
              </div>
            </div>
            <div className="SecondaryGrid">
              <div
                className="SecondaryBody"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomWidth: 1,
                  paddingBottom: "1%",
                  marginTop: "0px",
                }}
              >
                <div
                  className="SecondaryTextTitleGSS"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    paddingRight: 0,
                  }}
                >
                  Basic Package
                </div>
                <div
                  className="SecondaryText"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    fontSize: ".8rem",
                    borderTopWidth: 0,
                    paddingTop: 0,
                    flex: 0,
                    padding: 0,
                    paddingLeft: "2%",
                    paddingRight: "1%",
                  }}
                >
                  Includes:
                </div>
                <div
                  className="SecondaryText"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    fontSize: ".8rem",
                    borderTopWidth: 0,
                    paddingTop: 0,
                    padding: 0,
                  }}
                >
                  pH, Phosphorus(P), Potassium(K), Sulfur(S),Calcium(Ca),
                  Magnesium(Mg), Sodium(Na), Zinc(Zn), Iron(Fe), Manganese(Mn),
                  Copper(Cu), Boron(B), and CEC
                </div>
              </div>
              <div
                className="SecondaryText"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  borderTopWidth: 0,
                  padding: 0,
                  paddingTop: "1%",
                }}
              >
                Thanks to a newer, cost-effective extraction method called
                Mehlic we can pull several elements all at once when running the
                soil samples through the lab. Making grid soil sampling a better
                value with more information.
              </div>
            </div>
            <div
              className="SecondaryGrid"
              style={{
                marginBottom: "0%",
              }}
            >
              <div
                className="SecondaryBody"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomWidth: 1,
                  paddingBottom: "1%",
                  marginTop: "0px",
                }}
              >
                <div
                  className="SecondaryTextTitleGSS"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    paddingRight: 0,
                    padding: 0,
                  }}
                >
                  Full Package
                </div>
                <div
                  className="SecondaryText"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    fontSize: ".8rem",
                    borderTopWidth: 0,
                    paddingTop: 0,
                    flex: 0,
                    padding: 0,
                    paddingLeft: "2%",
                    paddingRight: "1%",
                  }}
                >
                  Includes:
                </div>
                <div
                  className="SecondaryText"
                  style={{
                    fontFamily: "Raleway,Helvetica,sans-serif",
                    fontSize: ".8rem",
                    padding: 0,
                    borderTopWidth: 0,
                    paddingTop: 0,
                  }}
                >
                  Every element from the basic package plus Organic Matter and
                  Nitrate Nitrogen
                </div>
              </div>
              <div
                className="SecondaryText"
                style={{
                  fontFamily: "Raleway,Helvetica,sans-serif",
                  fontSize: ".8rem",
                  borderTopWidth: 0,
                  padding: 0,
                  paddingTop: "1%",
                }}
              >
                Included in the Full package we will have Nitrogen values for
                variable rating N applications. At the present time we only
                offer 6-8" sample depths.
              </div>
            </div>
            <div
              className="SecondaryText"
              style={{
                fontFamily: "Raleway,Helvetica,sans-serif",
                fontSize: ".8rem",
                borderTopWidth: 0,
                width: "100%",
                marginLeft: "2.5%",
                paddingLeft: "0%",
                paddingRight: "0%",
                paddingBottom: "5%",
              }}
            >
              With each grid sampling job, you will receive a printed copy of
              each nutrient soil sample results along with fertilizer
              recommendations. When you are ready to apply a variable rate
              fertilizer application just call and let us know. We will send the
              VTR files to your choice of fertilizer supplier.
            </div>
          </div>

          <div className="GridBar1"></div>
          <div className="GridSample1">
            <img src={"/Pictures/pH.jpg"} className="GridPhoto" alt="" />
          </div>

          <div className="GridBar2"></div>

          <div className="GridSample2">
            <img
              src={"/Pictures/Phosphorus.jpg"}
              className="GridPhoto"
              alt=""
            />
          </div>

          <div className="GridBar3"></div>

          <div className="GridSample3">
            <img src={"/Pictures/Potassium.jpg"} className="GridPhoto" alt="" />
          </div>

          <div className="GridBar4"></div>

          <div className="GridSample4">
            <img src={"/Pictures/11-52-0.jpg"} className="GridPhoto" alt="" />
          </div>
        </div>
        <div className="Policy"></div>
      </div>
    </>
  );
}
