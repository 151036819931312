import React from "react";

export default function PivotBio() {
  return (
    <>
      <div className="parallaxPivot">
        <div className="SecondaryTextTitle">Pivot Bio</div>
      </div>
      <div className="GridCont">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBoxLink">
          <div style={{}}>
            <img
              src={"/Pictures/Logo-removebg no name.png"}
              className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
              alt=""
            />
          </div>
          <div className="SecondaryBody">
            <div className="SecondaryTextLineLink">
              <div style={{ flex: 1, paddingRight: "1%" }}>
                <div
                  className="SecondaryText"
                  style={{ paddingBottom: "2%", flex: 1 }}
                >
                  Pivot Bio is an innovative agricultural biotechnology company
                  that is transforming the way farmers approach nitrogen
                  fertilizer application. Nitrogen is an essential nutrient for
                  plant growth, and traditionally, farmers have relied on
                  synthetic nitrogen fertilizers to meet crop demands. However,
                  these fertilizers come with several challenges, including
                  environmental pollution, nutrient runoff, and high costs.
                  Pivot Bio has developed a groundbreaking solution that
                  harnesses the power of naturally occurring microbes to provide
                  crops with the nitrogen they need while reducing the reliance
                  on synthetic fertilizers.
                </div>
              </div>
              <div className="SecondaryImgCont">
                <a
                  href="https://pivotbio.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="SecondaryLink"
                >
                  <div className="LinkImgCont">
                    <img
                      src={"/Pictures/PivotBio.png"}
                      className="LinkImg"
                      alt=""
                    ></img>
                  </div>
                  <div>
                    <div className="LinkText">
                      <div className="special">Link</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
