import React from "react";
import { Link } from "react-router-dom";
import { useHover } from "@uidotdev/usehooks";
import { useState } from "react";
export default function Navbar() {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  return (
    <div
      className={active ? "ContainerNavbarOnHover" : "ContainerNavbar"}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div
        className="nav-link-cont"
        onClick={() => {
          setActive(true);
        }}
      >
        <div className="nav-link">
          <i className="fa-solid fa-angles-down fa-2xl"></i>
        </div>
      </div>
      <ul className="NavBtnFlex">
        <li className="Btn">
          <Link
            to={""}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Home
          </Link>
        </li>
        <li
          className="Btn"
          onMouseEnter={() => setActive3(true)}
          onMouseLeave={() => setActive3(false)}
        >
          <div className="BtnLeft">
            <Link
              to={"Channel"}
              className="BtnLeft"
              onClick={() => {
                setActive(false);
                setActive3(false);
              }}
            >
              Channel
            </Link>

            <ul className={active3 ? "HiddenListOnHover" : "HiddenList"}>
              <li className="HiddenListItem">
                <Link
                  to={""}
                  //to={"ChannelCorn"}
                  className="BtnLeft"
                  onClick={() => {
                    setActive(false);
                    setActive3(false);
                  }}
                >
                  Corn
                </Link>
                <Link
                  to={""}
                  //to={"ChannelSoybeans"}
                  className="BtnLeft"
                  onClick={() => {
                    setActive(false);
                    setActive3(false);
                  }}
                >
                  Soybeans
                </Link>
                <Link
                  to={""}
                  //to={"ChannelGrainSorghum"}
                  className="BtnLeft"
                  onClick={() => {
                    setActive(false);
                    setActive3(false);
                  }}
                >
                  Grain Sorghum
                </Link>
                <Link
                  to={""}
                  //to={"ChannelForages"}
                  className="BtnLeft"
                  onClick={() => {
                    setActive(false);
                    setActive3(false);
                  }}
                >
                  Forages
                </Link>
              </li>
              {/*<li className="HiddenListItem">
                <Link
                  to={""}
                  className="HiddenListItem"
                  onClick={() => {
                    setActive(false);
                  }}
                >
                  Example 2
                </Link>
              </li>
              <li className="HiddenListItem">
                <Link
                  to={""}
                  className="HiddenListItem"
                  onClick={() => {
                    setActive(false);
                  }}
                >
                  Example 3
                </Link>
                </li>*/}
            </ul>
          </div>
        </li>
        <li className="Btn">
          <a
            href="https://www.gostarseed.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="BtnLeft"
          >
            Cover Crops
          </a>
        </li>
        <li
          className="Btn"
          onMouseEnter={() => setActive2(true)}
          onMouseLeave={() => setActive2(false)}
        >
          <div className="BtnLeft">
            <div className="BtnLeft">Alfalfa</div>

            <ul className={active2 ? "HiddenListOnHover" : "HiddenList"}>
              <li className="HiddenListItem">
                <Link
                  to={"WLAlfalfa"}
                  className="BtnLeft"
                  onClick={() => {
                    setActive(false);
                    setActive2(false);
                  }}
                >
                  WL Alfalfa
                </Link>
              </li>
              {/*<li className="HiddenListItem">
                <Link
                  to={""}
                  className="HiddenListItem"
                  onClick={() => {
                    setActive(false);
                  }}
                >
                  Example 2
                </Link>
              </li>
              <li className="HiddenListItem">
                <Link
                  to={""}
                  className="HiddenListItem"
                  onClick={() => {
                    setActive(false);
                  }}
                >
                  Example 3
                </Link>
                </li>*/}
            </ul>
          </div>
        </li>

        <li className="Btn">
          <Link
            to={"ClimateFieldView"}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Climate Field View
          </Link>
        </li>
        <li className="Btn">
          <Link
            to={"PivotBio"}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Pivot Bio
          </Link>
        </li>
        <li className="Btn">
          <Link
            to={"GridSoilSampling"}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Grid Soil Sampling
          </Link>
        </li>

        <li className="Btn">
          <Link
            to={"SeedWheat"}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Seed Wheat
          </Link>
        </li>
        <li className="Btn">
          <Link
            to={"TestPlots"}
            className="BtnLeft"
            onClick={() => {
              setActive(false);
            }}
          >
            Test Plots
          </Link>
        </li>
      </ul>
    </div>
  );
}
