import React from "react";

export default function ClimateFieldView() {
  return (
    <>
      <div className="parallaxCFV">
        <div className="SecondaryTextTitle">Climate FieldView</div>
      </div>
      <div className="GridCont">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBoxLink">
          <div style={{}}>
            <img
              src={"/Pictures/Logo-removebg no name.png"}
              className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
              alt=""
            />
          </div>
          <div className="SecondaryBody">
            <div className="SecondaryTextLineLink">
              <div style={{ flex: 1, paddingRight: "1%" }}>
                <div
                  className="SecondaryText"
                  style={{ paddingBottom: "2%", flex: 1 }}
                >
                  Climate FieldView uses real-time data to harness the power of
                  precision agriculture. By collecting and integrating data from
                  various sources. It allows our clients to gather information
                  through sources like weather stations, soil moisture probes,
                  and satellite imagery.
                </div>
              </div>
              <div className="SecondaryImgCont">
                <a
                  href="https://climate.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="SecondaryLink"
                >
                  <div className="LinkImgCont">
                    <img
                      src={"/Pictures/FieldView.png"}
                      className="LinkImg"
                      alt=""
                    ></img>
                  </div>
                  <div>
                    <div className="LinkText">
                      <div className="special">Link</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
